.modal_view_label_end {
  height: 100vh;
  width: 25vw;
  background-color: var(--bg-primary);
  border-left: 1px solid var(--border-color);

  box-shadow: rgba(28, 40, 64, 0.12) 0px 8px 28px -6px, rgba(28, 40, 64, 0.14) 0px 18px 88px -4px;

  overflow: hidden;
  z-index: 9999;
  will-change: transform, box-shadow;
  transform-origin: 50% center;
}

.mb-16 {
  margin-bottom: 8px;
}
.client-list-container {
  padding: 16px 22px;
  width: 75vw;
}

@media (max-width: 768px) {
  .modal_view_label_end {
    width: auto;
  }
  .client-list-container {
    width: auto;
    padding: 0px;
  }
  .client-search-container {
    top: 85px;
  }
}

@media (max-width: 400px) {
  .modal_view_label_end {
    width: 100vw;
  }
}
