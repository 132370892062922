.global-search-modal {
  height: fit-content;
  width: 60%;
  background: var(--bg-primary);
  border-radius: 4px;
  margin-top: 5vh;
  overflow: hidden;
}

.global-search-header {
  padding: 40px 20px 0px 20px;
  z-index: 99;
}

.global-search-result {
  --screen-height: 80vh;
  --top-padding: 30px;
  /* --button-space: 60px; */
  --head-font-size: 18px;
  --offset-size: 120px;
  max-height: calc(
    var(--screen-height) - var(--top-padding) - var(--head-font-size) - var(--offset-size)
  );
  overflow-y: auto;
  margin: 5px 20px 30px 20px;
}

.global-search-box {
  border: 1px solid var(--input-border);
  padding: 2px 7px 2px 7px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  gap: 5px;
  height: 34px;
  background: var(--bg-primary);
}

.global-search-box:focus-within {
  border: 1px solid #3454d1;
  outline: 3px solid rgba(52, 84, 209, 0.25); /* Use outline instead of box-shadow */
}

.result-heading {
  font-size: 12px;
  color: var(--second-text-color, #87909e);
  font-weight: 600;
  text-transform: uppercase;
}

.result-item-container {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  border-radius: 4px;
  align-items: center;
  padding: 9px 10px;
  gap: 10px;
}

.result-item-container:hover {
  background: var(--border-color);
}

.result-item-container:last-child {
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 10px;
}

.task-icon-container {
  padding: 10px;
  border-radius: 100%;
  background: #3454d1;
}

.project-icon-container {
  border-radius: 100%;
  background: #dd476a;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.redirection-icon {
  cursor: pointer;
  border-radius: 4px;
  /* height: 20px;
  width: 10px; */
}
.redirection-icon:hover {
  background: var(--bg-primary);
}

.global-search-chat-message {
  display: -webkit-box;
  line-break: auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.global-search-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 768px) {
  .global-search-modal {
    width: 90%;
  }
}
