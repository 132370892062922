/* =================workflow list css========== */
.workflow-container {
  display: flex;
  margin-top: 3%;
  margin-left: 35%;
  background-color: var(--bg-light-gray); /* Light background */
}

.workflow-content {
  text-align: center;
  padding: 20px;
  border-radius: 10px;
}

.workflow-image {
  width: 100px; /* Adjust image size */
  margin-bottom: 10px;
}

.create-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-button:hover {
  background-color: #0056b3;
}
/* workflow list css */

/* =================workflow ADD css========== */
/* Tabs Container */
.tabs-container {
  font-family: Arial, sans-serif;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* Tabs Header */
.tabs-header {
  display: flex;
  border-bottom: 1px solid #ddd;
}

/* Tab Labels */
.tab-label {
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 600;
  color: #888;
  cursor: pointer;
  transition: color 0.3s ease;
  border-bottom: 2px solid transparent;
}

/* Active Tab Label */
.tab-label.active {
  color: #000;
  border-bottom: 2px solid #2f55ff; /* Blue underline */
}

/* Tab Content */
.tab-content {
  padding: 15px;
  font-size: 14px;
  color: #333;
}

/* Form Styling */
.workflow-form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.form-label {
  font-size: 14px;
  margin-bottom: 4px;
  color: #333;
}

.form-input,
.form-textarea {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px 10px;
  font-size: 14px;
}

.form-input {
  height: 36px;
}

.form-textarea {
  resize: vertical;
  min-height: 80px;
}

/* =================workflow ADD css========== */

.modal-view-trigger {
  height: 100vh;
  width: 20%;
  background-color: white;
}

.trigger-modal-container {
  display: flex;
  flex-direction: column;
  width: 100%; /* Adjust as needed */
  height: 100vh; /* Take full viewport height */
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
}

/* Make sure the parent modal overlay is full screen and centers the modal */
.modal-view-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* ensures the container can stretch full height of viewport */
  /* If needed, add width: 100vw; and position: fixed; top:0; left:0; */
}

.trigger-modal-header {
  background: #f9fafb;
  padding: 16px;
  border-bottom: 1px solid #e5e7eb;
  flex-shrink: 0;
}

.trigger-modal-title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.trigger-modal-subtitle {
  margin: 4px 0 0;
  font-size: 14px;
  color: #6b7280;
}

/* Body will now scroll within the full-height container */
.trigger-modal-body {
  padding: 16px;
  overflow-y: auto;
  flex: 1;
}

/* Sticky footer at the bottom of the container */

.cancel-button {
  background: #e5e7eb;
  color: #374151;
}

.save-button {
  background: #3b82f6;
  color: #fff;
}

.trigger-modal-body {
  padding: 16px;
}

/* Each item */
.trigger-item {
  margin-bottom: 12px;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 12px;
  transition: background-color 0.2s ease;
}

/* Hover effect */
.trigger-item:hover {
  background-color: #f9fafb;
}

/* When selected, give a highlighted background */
.trigger-item.selected {
  background-color: #e0f2fe; /* light blue */
  border: 1px solid #3b82f6; /* blue border */
}

/* Hide the default radio button visually */
.trigger-input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

/* The label is responsible for displaying text */
.trigger-label {
  font-size: 14px;
  display: inline-block;
  width: 100%;
}

/* Ensure clicking anywhere in the .trigger-item selects the radio */
.trigger-item {
  display: flex;
  align-items: center;
}

.trigger-content {
  display: flex;
  flex-grow: 1;
  padding: 5px 10px;
  flex-direction: column;
}

/* trigger creation node */
.trg-container {
  background: #fff;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  position: relative;
  font-family: sans-serif;
  padding: 10px 18px;
}

.trg-flash-icon-container {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.trg-flash-icon {
  background: #e1e1e1;
  padding: 5px;
  border-radius: 200px;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trg-main-text {
  font-size: 15px;
  color: #333;
  font-weight: 500;
  margin-bottom: 10px;
}

.trg-button-container {
  display: flex;
  justify-content: center;
}

.trg-trigger-button {
  display: flex;
  background: #f0f0f0;
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;
  gap: 4px;
  align-items: center;
  padding: 4px 8px;
}

.trg-trigger-button-text {
  font-size: 12px;
  font-weight: 400;
}
/* trigger creation node */

/* ====node style==== */
/* Container styles */
.nd-container {
  background: #fff;
  border: 1px solid #e1e1e1;
  position: relative;
  font-family: sans-serif;
  padding: 2px 2px 11px 2px;
  border-radius: 0px 8px 8px 8px;
  min-width: 180px;
  max-width: 250px;
}

/* Header styles */
.nd-header {
  display: flex;
  padding: 3px 14px 3px 3px;
  position: absolute;
  top: -22px;
  left: -1px;
  border: 1px solid #e1e1e1;
  border-bottom: none;
  gap: 4px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  z-index: -1;
}

.nd-header-icon {
  color: #d2d2d2;
}

.nd-header-text {
  font-size: 11px;
  font-weight: 500;
}

/* Content styles */
.nd-content {
  padding: 7px 7px 7px 4px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: flex-start;
}

.nd-content-icon {
  color: white;
  background: black;
  padding: 1px 3px;
  border-radius: 4px;
}

.nd-content-text {
  font-size: 12px;
  font-weight: bold;
}

.nd-divider {
  height: 0.5px;
  background: #d2d2d2;
}

.nd-description {
  padding: 5px 5px 8px 5px;
  font-size: 13px;
}

.nd-description-bold {
  font-weight: 600;
}

/* Handle styles */
.nd-handle {
  background: #e1e1e1;
}

/* Add icon styles */
.nd-add-icon {
  position: absolute;
  display: flex;
  background: black;
  border-radius: 200px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 4px;
  bottom: -25%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nd-add-icon svg {
  color: white;
}
/* ====node style==== */
