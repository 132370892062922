.related-task-name {
  color: var(--main-text-color, #2a2e34);
  leading-trim: both;
  text-edge: cap;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
}

.related-task-container {
  border-radius: 4px;
  border: 1px solid var(--border-color);
  padding: 0px;
}

.related-task-item-container {
  min-height: 40px;
  padding: 8px 10px;
}

.drop-arrow {
  display: inline-flex;
  padding: 6px 8px;
  align-items: flex-start;
  gap: 10px;
}

.related-task-item-text {
  color: var(--main-text-color, #2a2e34);
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
