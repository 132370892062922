.activity-container {
  display: flex;
  align-items: flex-start;
  margin: 0;
  border-radius: 5px;
  background-color: var(--bg-primary);
  justify-content: space-between;
  width: 100%;
  gap: 15px;
  border-bottom: 1px solid #f2f3f8;
  padding: 16px 10px;
  /* max-width: 500px; */
}

.activity-container:hover {
  background-color: #f4f4f7b6;
}

.profile-pic {
  border-radius: 50%;
  margin-right: 10px;
  width: 46px;
  height: 46px;
}

.activity-content {
  display: flex;
  align-items: center;
  gap: 3px;
}

.activity-details {
  display: flex;
  flex-direction: column;
  max-width: 90%;
}

.activity-user {
  color: var(--main-text-color, #2a2e34);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  flex: 1 1;
  justify-content: space-between;
}

.status-change {
  display: flex;
  align-items: center;
}

.status {
  display: inline-flex;
  padding: 5px 6px;
  align-items: center;
  gap: 7px;
  border-radius: 5px;
  border: 1px solid var(--hover, #eaebef);
}

.in-progress {
  color: var(--main-text-color, #2a2e34);
  leading-trim: both;
  text-edge: cap;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.completed {
  background-color: #4caf50;
  color: var(--bg-primary);
}

.arrow {
  margin: 0 5px;
}

.capital-text {
  text-transform: capitalize;
}
.activity-timestamp {
  color: var(--main-text-color);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.activity-status-dot {
  width: 6px;
  height: 6px;
  border-radius: 13px;
}

.label-item-log {
  border-radius: 4px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 9px;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 11px;
}

.priority-item-log {
  border-radius: 100px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 4px 10px;
  background-color: #e5eeff;
  color: #1063ff;
  font-weight: 500;
  font-size: 11px;
  line-height: normal;
  display: inline-flex;
}

.work-activity-container {
  max-width: 695px;
  /* border: 1px solid rgb(240, 240, 240); */
  /* margin: var(--top-breadcrumb-padding); */
  /* border-radius: 4px; */
}

.description-container {
  font-size: 13px;
  max-width: 304px;
  min-width: min-content;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid rgb(240, 240, 240);
}

.activity-reports-header-container {
  display: flex;
  justify-content: space-between;
  /* margin: 14px 0px; */
}
.activity-reports-filter-container {
  display: flex;
  justify-content: space-between;
  gap: 4px;
}
.time-history {
  padding: var(--top-breadcrumb-padding-x);
}

@media (max-width: 768px) {
  .work-activity-container {
    margin: var(--top-breadcrumb-padding-responsive);
    margin: 70px 0px 0px 0px;
    padding: 0px 17px;
  }
  .activity-reports-header-container {
    margin: 5px 3px;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 90%;
  }
  .activity-content {
    flex-wrap: wrap;
  }
  .activity-reports-filter-container {
    flex-direction: column;
    gap: 15px;
  }
  .work-activity {
    margin-top: 100px;
  }
  .activity-select .css-1mdogia-control {
    max-width: 100%;
  }
  .activity-select .css-50tpvr-control:hover {
    max-width: 100%;
  }
  .time-history {
    padding: 0px;
  }
  .time-history .heading {
    position: absolute;
    top: 55px;
    z-index: 10;
    width: 100%;
    background: #fafafb;
    padding: 5px 17px 5px 17px;
  }
  .time-history .table_outer {
    margin: 240px 17px 0px 17px;
  }
  .time-history-filter-container {
    flex-direction: column;
    position: absolute;
    top: 65px;
    z-index: 10;
    width: 100%;
    background: #fafafb;
    padding: 0px 17px 20px 17px;
    box-shadow: 0px 5px 20px var(--bg-light-gray);
  }
}
