.password-icon {
  position: absolute;
  color: black;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.my-team-th:nth-child(6) {
  /* text-align: center; */
}

/* input:has(+ .error-block) {
  border: 1px solid red;
}
.password-block:has(.error-block) input {
  border: 1px solid red;
} */

.error-block {
  font-size: 11px;
  color: red;
  margin-top: 2px;
}

/* react-select:has(+ .error-block) {
  border: 1px solid red;
} */

.table-reportinguser {
  scrollbar-width: thin;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.my-team-head {
  font-size: 1rem;
  font-weight: 600;
  line-height: 20.16px;
}

.field-required {
  color: red;
}

/* .input-box {
  width: 100%;
  border: 0.5px solid var(--second-text-color);
  border-radius: 3px;
  height: 28px;
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  line-height: 17.64px;
  outline: none;
}
.input-box:focus {
  border: 1px solid #3454d1;
  outline: 3px solid rgba(52, 84, 209, 0.25);
}
.input-box::placeholder {
  color: var(--second-text-color);
} */

.box-focus {
  border: 0.5px solid var(--second-text-color);
}

.box-focus:focus {
  border: 1px solid #3454d1;
  box-shadow: none;
  outline: 3px solid rgba(52, 84, 209, 0.25); /* Use outline instead of box-shadow */
}
.search_input_contain {
  border: 1px solid #d1d1d1;
  padding: 5px 18px 5px 5px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  gap: 5px;
  font-size: 13px;
  min-height: 34px;
}
.search_input_contain:focus {
  border: 2px solid #3454d1;
  outline: none; /* Optional: removes the default outline */
  box-shadow: none;
}

.img-icon-size {
  width: 15px;
  height: 17px;
  /* margin-right: 6px; */
}
.slash-img-icon-size {
  width: 7px;
  height: 17px;
  margin-right: 6px;
}

.myTeam-container {
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: lightgray 0px 0px 2px 0px;
  margin-bottom: 30px;
  font-family: inter;
}

.myTeam-table-container {
  max-height: 52vh;
  min-height: 52vh;
  overflow-y: auto;
  border: 1px solid var(--border-color);
  border-radius: 4px 4px 0px 0px;
  background: var(--bg-primary);
}

.value-font-13 {
  font-size: 13px;
  font-weight: 500;
}

.url-container {
  border-bottom: 1px solid var(--border-color);
  background: var(--bg-primary);
  /* z-index: 10000; */
}

.icon-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-primary);
  border-radius: 4px;
  height: 35px;
  width: 35px;

  /* height: 25px; */
  /* width: 30px; */
  border: 1px solid var(--border-color);
  color: var(--second-text-color);
  cursor: pointer;
}

.icon-bg:hover {
  border: 1px solid var(--second-text-color);
}

tr:hover .icon-div {
  visibility: visible !important;
}

.myteam-add-container {
  --top-bar-height: 55px;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.875rem;
  max-height: calc(100vh - var(--top-bar-height) - 110px);
  /* height: calc(100vh - var(--top-bar-height)); */
  overflow: auto;
}

.add-plus-btn-bg {
  background-color: rgb(52, 84, 209);
  border-radius: 16px;
  width: 18px;
  position: absolute;
  left: calc(100% - 25px);
  top: calc(100% - 24px);
  align-items: center;
  display: flex;
}

.myteam-table-container {
  /* border: 1px solid var(--border-color); */
  padding: var(--top-task-tab-padding);
  border-radius: 10px;
  width: 80%;
}

.add_item_icon_bg {
  font-size: 14px;
  color: var(--second-text-color);
  border-radius: 5px;
  padding: 4px 12px 4px 12px;
  cursor: pointer;
  border: none;
}
.add_item_icon_bg_active {
  font-size: 14px;
  background-color: var(--btn-hover);
  color: #3454d1;
  border-radius: 5px;
  padding: 4px 12px 4px 12px;
  cursor: pointer;
  border: none;
}
.add_item_icon_bg:hover {
  background-color: #f3f5f7;
}

.myteam_line {
  height: 1px;
  width: 100%;
  background: var(--bg-light-gray);
  position: absolute;
  top: 30px;
  z-index: -1;
}

.my-team-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 13px;
}

.team-list,
.team-add-modal {
  width: 92vw;
}

@media (max-width: 768px) {
  .myteam-table-container {
    /* padding: var(--top-breadcrumb-padding-responsive); */
    padding: 0px;
    background: var(--bg-primary);
  }
  .my-team .url-container {
    padding: 0px 6px;
  }
  .my-task-tabs {
    position: absolute;
    top: 88px;
    width: 100%;
    margin: 0px;
  }
  .my-team-list .tab_contain {
    position: relative;
    top: 85px;
    margin-top: 0px;
    padding: 6px 17px 0px 17px;
  }
  .myTeam-table-container {
    top: 100px;
    position: relative;
    padding: 0px 17px;
    max-height: 66vh;
    padding-bottom: 30px;
  }
  .task_tabs {
    padding: 6px 17px 0px 17px;
  }
  .my-team-row {
    flex-direction: column;
  }
  .team-list,
  .team-add-modal {
    width: 92vw;
  }
  .dob-text {
    text-wrap: nowrap;
  }
  .sub_padding {
    margin-top: 90px;
    position: relative;
  }
  .my-team-main {
    position: relative;
    overflow: scroll;
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 !important;
    margin-top: 75px !important;
  }
}

.last-emp-code {
  position: absolute;
  font-size: 10px;
  font-weight: 600;
  left: 0;
  top: 32px;
}

.pl-0 {
  padding-left: 0px;
}

.my-team-main {
  margin: 16px;
  background-color: var(--bg-primary);
  padding: 20px;
}

.my-team-head {
  color: var(--main-text-color, #2a2e34);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* calendar -css - section  */

.calendar-view {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.calendar-header {
  display: grid;
  grid-template-columns: 1.4fr repeat(7, 1fr);
  background-color: #f8faff;
  min-height: 80px;
}

.day-calendar {
  color: var(--second-text-color, #67677f);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.date-calendar {
  color: var(--main-text-color, #07082f);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.day-header {
  padding: 10px;
  border-left: 1px solid var(--border-color);
  margin: auto 0;
}

.calendar-row {
  display: grid;
  grid-template-columns: 1.4fr repeat(7, 1fr);
  /* align-items: center; */
  min-height: 100px;
  border-top: 1px solid var(--border-color);
}

.user-info {
  display: flex;
  align-items: center;
  padding: 10px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-break: auto;
  min-height: 80px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.task-cell {
  border-left: 1px solid var(--border-color);
  height: 100%;
  cursor: pointer;
  max-width: 147px;
}

.task-cell:hover {
  background-color: #eff1ff;
}

.task-calendar {
  border-radius: 4px;
  padding: 10px;
  margin: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.pink {
  height: 90px;
  background-color: var(--color-blue-bg);
  color: var(--color-blue-text);

  /* border: 1px solid #f5c2c7; */
}

.purple {
  background-color: var(--color-green-bg);
  color: var(--color-green-text);
  height: 90px;
}

.red {
  background-color: #f44336;
  border: 1px solid #d32f2f;
  height: 90px;
}

.task-priority {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  padding: 1px 5px;
  border-radius: 100px;
  border: 0.5px solid var(--text-dark);
  color: var(--text-dark);
  width: fit-content;
}
.task-name {
  font-size: 14px;
  margin: 10px 0;
  font-weight: 600;
  display: -webkit-box; /* Use flexbox model */
  -webkit-line-clamp: 1; /* Limit to 2 lines */
  -webkit-box-orient: vertical; /* Ensure the text is oriented vertically */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis for overflow */
  line-height: 1.2; /* Adjust line height as needed */
  max-height: 2.4em; /* Set maximum height for 2 lines */
}

.duration {
  font-size: 10px;
}

.calendar-header-filter {
  padding: auto;
  height: 28px;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  cursor: pointer;
}
.calendar-header-filter:hover {
  border: 0.5px solid var(--second-text-color);
}

.calendar-header-filter.task-active {
  background-color: var(--color-green-bg);
  color: var(--color-green-text);
}

.calendar-header-filter.subtask-active {
  background-color: var(--color-blue-bg);
  color: var(--color-blue-text);
}
.check-date .rdrDateRangePickerWrapper {
  width: 530px;
}

.date-picker-team-view {
  height: 28px;
  font-size: 13px;
  border: 0.5px solid var(--border-color);
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  background: var(--bg-primary);
  font-weight: 500;
}

.date-picker-team-view:hover {
  border: 0.5px solid var(--second-text-color);
}

.calendar-outer {
  border: 0.5px solid var(--border-color);
  overflow-x: auto;
  max-width: 100%; /* Prevent the grid from stretching beyond the outer container */
}

@media (max-width: 768px) {
  .calendar-outer {
    /* Add any adjustments you may need for smaller screens */
    width: 100%; /* Ensure it doesn’t exceed the viewport */
  }
  .invite-modal-view {
    width: 35vw;
  }
}

.week-calendar-style .rdrInputRanges {
  display: none;
}

.username-box {
  width: 100%;
  border: 0.5px solid var(--second-text-color);
  border-radius: 3px;
  height: 34px;
  padding: 0.375rem 0.75rem;
  font-size: 13px;
  line-height: 17.64px;
  outline: none;

  border: 1px solid #d13434;
  outline: 3px solid rgba(209, 52, 52, 0.25); /* Use outline instead of box-shadow */
}
