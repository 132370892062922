.timesheet-nodata {
  min-height: 63vh;
  border: 1px solid var(--border-color);
  border-radius: 3px;
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* width: 82%; */
  gap: 4px;
}

.timesheetcontainer {
  background-color: var(--bg-primary);
}

.timesheet-nodataiconbg {
  width: 57px;
  height: 57px;
  border-radius: 57px;
  object-fit: cover;
  background-color: var(--bg-light-gray);
}
.timesheet-nodatatxt {
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-weight: 500;
  line-height: 16.38px;
  /* text-align: left; */
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--text-dark);
}

.timesheet-nodatasubtext {
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.12px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--second-text-color);
}

.timesheetgeneratebtn {
  width: 85px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 0.5px solid var(--border-color);
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.12px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  cursor: pointer;
}

.timesheet-add-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding: 10px;
}
.timesheet-formitem {
  display: flex;
  flex-direction: column;
}

.timesheet-formitem-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.timesheet-btn-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.timesheet-btn {
  width: 100%;
  height: 34px;
  border: 1px solid var(--border-color);
  border-radius: 3px;
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-weight: 600;
  line-height: 16.38px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
  background-color: var(--bg-primary);
}

.timesheet-date {
  width: 48% !important;
}

.minwid-300 {
  min-width: 300px !important;
}

.timesheet-data-container {
  /* border: 1px solid var(--border-color); */
  border-radius: 3px;
  /* margin: 20px; */
  min-height: 63vh;
  background-color: var(--bg-primary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.timesheet-title-container {
  display: flex;
  justify-content: space-between;
  /* gap: 6px; */
  padding: 10px 0px;
  align-items: center;
}

.timesheet-name {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--text-dark);
  max-width: 325px;
}

.timesheet-date {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.64px;
  text-align: left;
  /* text-underline-position: from-font; */
  text-decoration-skip-ink: none;
  color: var(--text-dark);
  cursor: pointer;
}

.timesheet-modal_view {
  min-height: 70vh;
  width: 85vw;
  background: var(--bg-primary);
  border-radius: 2px;
  margin-top: 50px;
  max-width: 1500px;
  max-height: 90vh;
}

.timesheet-td-18 {
  min-width: 18vh;
}

.timesheet-tablemain-conatiner {
  border-radius: 4px 4px 0px 0px;
  max-width: 1500px;
  padding: 16px 24px;
  border-radius: 4px;
}

.timesheet-table {
  /* padding: 17px; */
}

.timesheet-table > tbody {
  border-width: 1px;
}

.timesheet-table-conatiner {
  overflow-y: scroll;
  /* border: 1px solid var(--border-color); */
  border-radius: 4px 4px 0px 0px;
  background: var(--bg-primary);
}

.timesheet-addtimelog {
  position: absolute;
  right: 52px;
  top: 50px;
  z-index: 1000;
  width: 30px;
  height: 30px;
}

.underline:hover {
  text-decoration: underline;
}

.timesheet-dateeditinput {
  width: 100%;
  border: none;
  text-decoration: underline;
  border-bottom: 1px solid rgb(180, 180, 180);
  padding: 4px;
}

.timesheet-dateeditinput:focus-visible {
  border: none;
  outline: none;
}

.timesheet-add-log-modal {
  min-height: 30vh;
  width: 60vh;
  background: var(--bg-primary);
  border-radius: 10px;
  margin-top: 10%;
  /* max-width: 1500px; */
  /* max-height: 90vh; */
}

.timesheet-addtimelog-container {
  padding: 10px 20px 20px 20px;
}

.timesheet-addinput {
  width: 250px;
}

.timesheet-addlog-btn {
  padding: 0px 15px;
  height: 34px;
  border: 1px solid var(--border-color);
  border-radius: 3px;
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-weight: 600;
  line-height: 16.38px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
  background-color: var(--bg-primary);
}
.timesheet-addlog-btn:hover {
  background-color: var(--hover, #f0f0f0);
}

.timesheet-addtimelog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.timesheet-add-headtxt {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--text-dark);
}

.bluebtn {
  background-color: #3454d1;
  color: var(--bg-primary);
}

.timesheet-edit-name {
  border: none;
  text-decoration: underline;
  text-decoration-color: #b6b6b6;
  width: 100%;
}

.timesheet-edit-name:focus-visible {
  outline: none;
}

.timesheet-hedsection {
  display: flex;
  justify-content: space-between;
  padding: 8px 2px;
  align-items: center;
}

.timesheet-add-label {
  font-family: Plus Jakarta Sans;
  font-size: 13px;
  font-weight: 500;
  line-height: 16.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #232529;
}

.timesheet-btn-disabled {
  opacity: 0.5px;
}

.timesheet-selectdate-option {
  border-radius: 4px;
  border: 1px solid var(--input-border);
  font-size: 13px;
  width: 120px;
  color: grey;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sheet-date-picker {
  height: 35px;
  border: 1px solid var(--input-border);
}

@media (max-width: 768px) {
  .timesheetcontainer {
    padding-top: 130px;
  }
  .timesheet-hedsection {
    top: 91px;
    position: sticky;
    padding: 16px;
  }

  .time-sheet-table-container {
    margin-top: 90px;
    padding: 2px 16px;
  }
  .timesheet-tablemain-conatiner {
    max-width: 100% !important;
    /* margin: 20px 18px; */
    border: 0px;
    padding: 0px;
  }
}
