.mail-template-wrapper-style {
  border: 1px solid lightgrey;
  padding: 5px;
  border-radius: 4px;
  font-size: 13px;
  cursor: text;
}

.milestone-editor-style .public-DraftStyleDefault-block {
  margin: 0;
}

.mail-template-wrapper-style .DraftEditor-editorContainer {
  height: 200px;
}

.done-btn {
  border-radius: 3px;
  cursor: pointer;
  background-color: rgb(52, 84, 209);
  color: white;
  border: none;
  outline: none;

  font-size: 13px;
  height: 30px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.btn-container {
  display: flex;
  justify-content: space-between;
  gap: 4px;
  padding: 10px 5px;
}

/* .emt-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
} */

.emt-header {
  padding: 8px 5px;
}

.emt-title {
  font-size: 15px;
  font-weight: bold;
}

/* .emt-content {
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
  padding: 4px 10px;
  overflow-y: auto;
} */

.emt-field {
  margin-top: 8px;
}

.emt-label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
}

.emt-input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  height: 35px;
  padding: 0 5px;
}

.emt-placeholder-info {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.emt-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
