:root {
  --color-blue-bg: #63abff2b;
  --color-blue-text: #007bff;

  --color-orange-bg: #f4433629;
  --color-orange-text: #ff7f00;

  --color-regular-bg: #e0e0e0;
  --btn-text-color: #4f4f4f;

  --color-red-bg: #f4433629;
  --color-red-text: #ff4d4d;

  --color-violet-bg: #ce4dff29;
  --color-violet-text: #07082f;

  --color-green-bg: #5eff5e45;
  --color-green-text: #4caf50;
}

.ib__sc__btn {
  line-height: 1.47em;
}

/* date switch */
.ib__sc__np__btn {
  background-color: var(--btn-hover);
}
.ib__sc__np__btn:hover {
  background-color: var(--btn-hover);
}
.ib__sc__header .ib__sc-form-control {
  background-color: var(--btn-bg-light) !important;
  color: var(--btn-text-color) !important;
  font-weight: 500 !important;

  border-color: var(--border-color) !important;
}

/* date switch end */
.ib__sc__table {
  box-shadow: none !important;
}
.ib__sc__table-th {
  background-color: var(--bg-light);
  color: var(--btn-text-color);
}
.ib__sc__table-out-week .ib__sc__table-th {
  background-color: var(--bg-light);

  color: var(--btn-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  min-height: 40px;
  z-index: 1 !important;
}
.ib__sc__table-month .ib__sc__table-th {
  font-weight: 500;
  background-color: var(--bg-light);
  color: var(--btn-text-color);
}
.ib__sc__btn-group {
  flex-wrap: nowrap !important;
  display: flex !important;
  gap: 5px;
  align-items: stretch;
  width: 100%;
  border-radius: 0.25rem;
  overflow: hidden;
}
.ib__sc__table-hr-box-week {
  background: var(--bg-primary);
  border-top: 1px solid var(--border-color) !important;
}
.ib__sc__table-th {
  border: 1px solid var(--border-color) !important;
  z-index: 0 !important;
}
.team-cell {
  width: 11rem;
}
.ib__sc__cell {
  border: 1px solid var(--border-color) !important;
}
.ib__sc__tb_week_time .ib__sc__cell {
  border: 1px solid #e8e8e8 !important;
}
.ib__sc__staring_event_box::before {
  display: none;
}

.ib__sc__event-box-month {
  border-radius: 0.7em;
}
.ib__sc__event-box {
  border-radius: 0.7em;
}

.ib__sc__event-box-view.ib__sc__staring_event_box {
  /* border-radius: 10px 0px 0px 10px; */
  display: flex;
}
.calendar__event__title_wrap {
  /* display: flex;

  align-items: c;
  align-items: center;
  padding: 0 10px;
  height: 100%; */
  display: flex;
  align-items: flex-start;
  align-items: normal;
  padding: 5px 0px;
  height: 100%;
  flex-direction: column;
  gap: 2px;
  justify-content: space-between;
}
.ib__sc__btn {
  background-color: transparent !important;
  border: 0.5px solid var(--hover, #eaebef);
  color: #546272;
  border-radius: 3px !important;
}
.ib__sc__btn:hover {
  background-color: #d9d9d9 !important;
  border-color: transparent !important;
}
.ib__sc__btn.active {
  border: 1px solid var(--Base-Color) !important;

  font-weight: 500;
}
.ib__sc__week-date-btn-group {
  border-radius: 8px !important;
}
.ib__sc__table-td__team_cell {
  display: flex;
  flex-direction: column;
}
.react-calendar-scedule {
  box-shadow: none !important;
  padding: 0px;
  background: transparent !important;

  border: none !important;
}
table.ib__sc__table-team td {
  background-color: var(--bg-primary);
  border-color: var(--border-color) !important;
}
.ib__sc__table {
  border-color: var(--border-color) !important;
}

.calendar-wrap {
  margin-bottom: 3em;
}
.calendar-wrap .react-calender-scedule {
  flex-direction: column;
  height: 100%;
}
.ib__sc__calender_switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.ib__sc_month_cell {
  position: unset;
}
.calendar-icon {
  width: 12px;
  height: 12px;
}
.calendar__event__title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  min-width: 0;
  font-weight: 500;
}
.ib__sc__event {
  padding: 0px !important;
}

.fs-10 {
  font-size: 10px;
}

.ib__sc__header {
  border: 1px solid var(--border-color);
  border-radius: 10px 10px 0px 0px;
  padding: 10px !important;
  border-bottom: none;
  background: var(--bg-primary);
}
.ib__sc-form-control {
  font-weight: bold !important;

  background-color: var(--bg-light-gray);
}
.ib__sc__month-date-btn-group {
  background-color: var(--bg-light-gray);
  margin-right: 0.8em;
  border-radius: 8px;
  overflow: hidden;
  border-color: var(--border-color);
}
.ib__sc__month-date__bt-text .ib__sc-form-control {
  font-weight: bold !important;
}
.calendar-btn-switch {
  font-family: Inter;
  letter-spacing: -0.02em;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  cursor: pointer;
  height: 28px;
  display: flex;
  align-items: center;
  padding: 4px 8px !important;
  border-radius: 8px;
  box-shadow: var(--button-shadow, 0px 2px 4px rgba(0, 0, 0, 0.1));
}

.calendar-task-btn.active {
  background-color: var(--color-blue-bg);
  color: var(--color-blue-text);
}
.calendar-subtask-btn.active {
  background-color: var(--color-green-bg);
  color: var(--color-green-text);
}
.calendar-reminder-btn.active {
  background-color: var(--color-red-bg);
  color: var(--color-red-text);
}
.calendar-log-btn.active {
  background-color: var(--color-violet-bg);
  color: var(--color-violet-text);
}
.calendar-event-btn.active {
  background-color: var(--color-orange-bg);
  color: var(--color-orange-text);
}
table.ib__sc__table-month td {
  width: 165px;
}
button.ib__sc__btn {
  height: 28px;
  border-radius: 8px !important;
  font-size: 12px;
  padding: 0px 10px;
  font-weight: 600 !important;
  border: none !important;
  color: var(--btn-text-color) !important;
  background: var(--bg-light-gray) !important;
}

.ib__sc_month_day {
  font-size: 1em !important;
  z-index: 0 !important;
}

.ib__sc__event-wrapper {
  z-index: unset !important;
}
.ib__sc__table-td__day_cell {
  position: relative !important;
}
.submit-button {
  background: var(--Base-Color);
  border: none;
  color: var(--btn-text-light);
  outline: none;
  border-radius: 3px;
  text-decoration: none;
}
table tr td.ib__sc__table-td {
  border-color: var(--border-color);
  background-color: var(--bg-primary);
}
.calendar table tr {
  border-color: var(--border-color);
}
.ib__sc__table-td-week.ib__sc__today .ib__sc__truncate {
  background-color: var(--bg-light-gray);
}
.task-modal-view {
  width: 90vw;
  margin-top: 30px;
  background: var(--bg-primary);
  max-height: 90vh;
  border-radius: 5px;
}
.task-modal-view .task-single-card {
  margin: 0;
}
.close-button {
  background: var(--bg-primary);
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.close-button-container {
  position: sticky;
  top: 0px;
  background: var(--bg-primary);
  z-index: 10;
  border-bottom: 1px solid var(--border-color);
  border-radius: 5px;
}
.task-body-view {
  overflow: auto;
  height: 87vh;
}
.task-body-view .chat-bg {
  top: 0;
}

.time-box-filter {
  display: flex;
  gap: 6px;
}

/* event css */

.calendar-date-picker {
  min-width: 100px;
  align-items: center;
  border-radius: 8px;
  padding: 0 0.5rem;
}
.transform {
  transition: width 0.5s ease-out, transform 0.5s ease-out;
}

.calendar-time-picker {
  /* width: 100px; */
  font-size: 12px;
  color: var(--second-text-color, #67677f);
  leading-trim: both;
  text-edge: cap;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}

.calendar-event-timeline-container {
  display: flex;
  gap: 0.25rem;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
}
.calendar-event-container {
  padding: 0 0.5rem;
}
/* event css end */
.calendar-event-note {
  font-size: 11px;
  padding: 0 6px;
}
.react-datepicker {
  margin-left: 140px;
}
@media (max-width: 768px) {
  .task-list-container .calendar-wrap {
    margin-top: 190px;
    display: flex;
  }
  .calendar-wrap {
    display: flex;
    margin-top: 15px;
  }
  .task-list-container .time-box-filter {
    position: absolute;
    top: 86px;
    z-index: 2;
    background-color: var(--bg-primary);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 22px;
    padding: 16px;
    width: 100%;
  }
  .time-box-filter {
    margin-top: 35px;
    display: grid;
  }
  .calendar-event-timeline-container {
    flex-direction: column;
  }
  .calendar-event-container {
    padding: 0 !important;
  }
}

.event_sub_task .ib__sc__event-box-week,
.event_sub_task .ib__sc__event-box-month {
  /* border: 1px solid var(--color-blue-text); */
}
.event_reminder .ib__sc__event-box-week,
.event_reminder .ib__sc__event-box-month {
  /* border: 1px solid var(--color-red-text); */
}
.event_task .ib__sc__event-box-week,
.event_task .ib__sc__event-box-month {
  /* border: 1px solid var(--color-green-text); */
}

.ib__sc__event-box-view {
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  padding: 5px 0px 0px 0.2px;
}

.calendar-avatar {
  display: flex;
  justify-content: flex-start;
  position: relative;
  bottom: 0px;
  left: 5px;
}

.ib__sc__table-td-week.ib__sc__today .ib__sc__table-hr-box-week {
  background-color: var(--bg-primary);
}

td.ib__sc__table-td.ib__sc__table-td-month.ib__sc__today {
  background-color: var(--bg-primary);
}

.ib__sc__week-date-btn-group {
  border: 1px solid var(--border-color);
}
