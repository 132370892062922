p {
  margin: 0;
}
.task-contain {
  /* padding: 16px 1px 1px 1px; */
  width: 30%;
  /* padding: 18px 0px; */
}
.task_note {
  color: #879096;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 19.5px */
}
.task-status-input-text {
  color: var(--second-text-color, #67677f);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.head-contain {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  align-items: center;
}
.head-with-arrow {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.owner-contain {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.task-select-style {
  width: 140px;
  font-size: 14px;
  padding: 0px;
}
.full-select-style {
  /* width: 140px; */
  font-size: 13px;
  padding: 0px;
}

.head-txt {
  color: var(--main-text-color, #07082f);
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.sub-txt {
  color: var(--main-text-color, #07082f);
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.arow {
  cursor: pointer;
}
.sub-head {
  font-size: 15px;
  font-weight: 500;
  opacity: 0.8;
}

.desc-txt {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.7;
  margin-bottom: 10px;
}

.owner-select-contain {
  margin-bottom: 10px;
}

.status-contain {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
  align-items: center;
}
.edit-txt {
  text-decoration: underline;
  font-size: 14px;
  opacity: 0.8;
}
.round {
  height: 6px;
  width: 6px;
  border-radius: 100px;
  background-color: var(--bg-light-gray);
}
.status-wrapper {
  display: flex;
  padding: 4px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 0.5px solid var(--border-color);
}
.status-txt {
  color: var(--main-text-color, #07082f);
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.add-new {
  display: flex;
  gap: 3px;
  align-items: center;
  /* background: #f2f2f2; */
  flex-direction: row;
  /* display: block; */
  cursor: pointer;
  margin-top: 15px;
}

.group-sub-head {
  font-size: 14px;
  font-weight: 500;
  opacity: 0.7;
  margin-top: 12px;
  margin-bottom: 8px;
}
.card-head-contain {
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
}
.card-contain {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
}

.card {
  border-radius: 4px;
  border: 0.5px solid #3454d1;
  background: var(--bg-primary);
  /* border: 1px solid #3f3939; */
  padding: 8px 10px 11px 10px;
  /* border-left-width: 4px; */
}
.card-other {
  border: 1px solid var(--border-color);
  padding: 8px 10px 11px 10px;
  /* border-left-width: 4px; */
  border-radius: 4px;
  margin-bottom: 15px;
}

.line {
  height: 1px;
  background-color: var(--border-color);
  width: 100%;
  margin-top: 10px;
}

.used-txt {
  font-size: 13px;
  opacity: 0.7;
  font-weight: 400;
  margin-top: 6px;
}

.organization-layout {
  padding-top: 15px;
}

.organization_list {
  padding: 10px 15px 10px 0px;
}
.org-nav-contain {
  display: flex;
  gap: 10px;
}

.department_list {
  padding: 10px 15px 10px 0px;
}

.sticky-div {
  width: 57%;
  position: sticky;
  bottom: 15px;
  background: var(--bg-primary);
  height: 41px;
  padding: 10px;
  z-index: 10;
  /* height: 85vh; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.emp-save-btn {
  border: none;
  padding: 4px 28px;
  border-radius: 2px;
  background: var(--Base-Color);
  cursor: pointer;
  display: flex;
  gap: 3px;
  align-items: center;
  color: var(--bg-primary);
  margin-left: 35%;
}

.sett-save-btn {
  border: none;
  padding: 4px 28px;
  border-radius: 2px;
  background: var(--Base-Color);
  cursor: pointer;
  display: flex;
  gap: 3px;
  align-items: center;
  color: var(--bg-primary);
  margin-left: 35%;
}

.add-status {
  min-height: 25vh;
  width: 25vw;
  background: var(--bg-primary);
  border-radius: 2px;
  margin-top: 50px;
  max-height: 70vh;
}
.status-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.status-container {
  display: block;
  padding: 15px 20px 15px 20px;
}

.status-head-txt {
  font-size: 16px;
  font-weight: 400;
}
.status-inp {
  width: 100%;
  height: 36px;
  border-radius: 2px;
  border: 1px solid #d1d1d1;
  outline: none;
  padding-left: 5px;
  font-size: 15px;
}
.status-check-wrap {
  display: flex;
  gap: 10px;
  margin-top: 13px;
  margin-bottom: 25px;
  align-items: center;
}
.check-bx-txt {
  font-size: 16px;
  opacity: 0.8;
}

.status-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}

.status-color {
  position: relative;
  height: 29px;
  width: 70px;
  background: red;
  overflow: hidden;
  border-radius: 2px;
}

.add-status {
  font-size: 17px;
  font-weight: 600;
}

.status-error {
  margin: 0;
  font-size: 13px;
  font-weight: 400;
  color: red;
}

.edit-dele-wrap {
  display: flex;
  gap: 10px;
}

.add-status-group {
  width: 30vw;
}

.group-head-txt {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.head-close-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 10px 20px;
}
.group-head {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 3px;
}

.group-section {
  padding: 1px 20px 20px 20px;
}

.group-color {
  position: relative;
  min-height: 20px;
  min-width: 20px;
  background: red;
  overflow: hidden;
  border-radius: 200px;
}

.status-non-inp {
  border: none;
  outline: none;
  padding: 8px 2px 8px 5px;
  width: 100%;
}
.status-wrap {
  position: relative;
  display: flex;
  gap: 10px;
  border: 1px solid #d2d2d2;
  align-items: center;
  padding: 0px 2px 0px 11px;
  margin-right: 5px;
  width: 100%;
  border-radius: 8px;
}

.priority-wrap {
  position: relative;
  display: flex;
  gap: 10px;
  /* border: 1px solid #d2d2d2; */
  align-items: center;
  /* padding: 0px 2px 0px 11px; */
  margin-right: 5px;
  width: 100%;
}
.dl-contain {
  padding: 1px 9px;
}

.group-save-cancel {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 20px;
}

.pos-default {
  position: absolute;
  top: -10px;
  /* left: 32px; */
  left: 38px;
  background: var(--bg-primary);
  padding: 0px 5px;
  font-size: 13px;
  opacity: 0.7;
}

.status-list {
  padding-top: 10px;
  min-height: 20vh;
  max-height: 46vh;
  /* background: red; */
  overflow: auto;
  overflow-x: hidden;
  /* scrollbar-width: thin; */
}

.status-error {
  font-size: 13px;
  font-weight: 400;
  color: red;
  margin-top: 2px;
}
.status-drag-contain {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  align-items: center;
}

#menu:checked ~ .menu-content {
  display: block;
}

.menu-content {
  height: 100px;
  width: 150px;
  background: red;
  position: absolute;
  right: -180px;
  display: none;
}

.status-delete {
  min-height: 1vh;
  max-height: 70vh;
  overflow-y: scroll;
  max-width: 450px;
}
.modal_footer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.status-del-contain {
  padding: 10px 10px 5px 10px;
}
.delete-icon-wrapper {
  display: flex;
  justify-content: center;
  margin: 11px 0px 11px 0px;
}

.del-description {
  /* padding: 15px 10px 16px 10px; */
  text-align: center;
  padding: 0 20px 20px 20px;
  color: rgb(79, 78, 78);
}

.error-txt {
  font-size: 12px;
  color: red;
  margin-top: 3px;
}

.loading-wrapper {
  display: flex;
  justify-content: center;
}

.loading-absolute-right {
  position: absolute;
  right: 25px;
  top: 70px;
}

.edit-dele-wrap .options {
  display: none;
  /* additional styling for options if needed */
}

.edit-dele-wrap input[type='radio']:checked + label + .options {
  display: block;
}

.options {
  position: absolute;
  min-height: 25px;
  background: var(--bg-light-gray);
  width: 100px;
  /* top: 5px; */
  /* left: 45px; */
  border-radius: 6px;
  z-index: 5;
  /* right: 0%; */
  left: 25px;
  padding: 10px 5px;
}
.option-line {
  height: 1px;
  background-color: #d2d2d2;
  margin: 5px 0px 5px 0px;
}

.option {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.additional-save-btn {
  border: none;
  padding: 4px 28px;
  border-radius: 2px;
  background: var(--Base-Color);
  cursor: pointer;
  display: flex;
  gap: 3px;
  align-items: center;
  color: var(--bg-primary);
  /* margin-left: 90%; */
}

.menu-tab {
  padding: 0 10px;
  margin-bottom: 10px;
  font-weight: 500;
  width: 180px;
  color: var(--btn-text-color);
  font-size: 14px;
  height: 26px;
  outline: none;
  border: none;
  background: var(--bg-light-gray);
}

.menu-tab:hover {
  background: var(--btn-hover);
}
.menu-tab:focus {
  background: var(--btn-hover);
}

.permission-title {
  color: var(--main-text-color);
  font-weight: 500;
}

.permission-note {
  color: var(--second-text-color);
}

.permission-checkbox {
  border-radius: 5px;
}

.permission-checkbox:checked {
  accent-color: var(--accent-color);
}
.company-table-container {
  max-width: 1100px;
  /* padding: 16px 24px; */
  border-radius: 4px;
}
.layout-head {
  width: 158px;
}
.org-add-button-container {
  display: flex;
  /* justify-content: end; */
  justify-content: flex-end;
  align-items: center;
  padding-top: 15px;
  gap: 10px;
}

.org-add-container {
  margin: 16px;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--bg-primary);
}
.sections {
  overflow: scroll;
  height: 80vh;
  width: 100%;
}
.settings_tab {
  height: 27px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  margin-bottom: 1px;
  padding: 0px 8px;
  transition: all 0.3s ease-in-out;
  width: 129px;
  text-decoration: none;
  cursor: pointer;
  justify-content: space-between;
}

.cursor_no_drop_settings_tab {
  height: 27px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  transition: all 0.3s ease-in-out;
  width: 129px;
  text-decoration: none;
  cursor: no-drop;
  justify-content: space-between;
}

.settings_tab_active {
  background: var(--bg-light-gray);
  text-decoration: none;
  color: var(--text-dark);
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.settings_tab_inactive {
  color: var(--text-dark);
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: var(--bg-primary);
}
.settings_tab_inactive:hover {
  background: #e5eeff;
  color: var(--second-text-color);
}

.description-text {
  color: var(--second-text-color);
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
}

.settings-text-input {
  border-radius: 8px;

  width: 318px;
  height: 30px;
}

.settings-more-menu {
  display: flex;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: var(--bg-primary);
}

.team-list-table {
  width: 552px;
}

.task_tabs_container {
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 18px;
}
