/* body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
} */

.board-container {
  width: 100%;
  height: 100%;
  /* padding: 10px 8px 8px 10px; */
  box-sizing: border-box;
  /* background-color: var(--bg-primary); */
  overflow-x: scroll;
  margin: 10px 0;
}
.board-container::-webkit-scrollbar-thumb {
  background-color: var(--bg-light-gray); /* Scrollbar thumb color */
  border-radius: 4px; /* Rounded corners */
}

.board-container::-webkit-scrollbar-thumb:hover {
  background-color: #eaebef; /* Thumb color on hover */
}

.board {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(330px, 1fr);
  gap: 20px;
  height: 100%;
}

.column {
  border-radius: 4px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--main-text-color);
}

.column-header {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: var(--bg-primary);
  border: 0.5px solid var(--border-color);
  border-radius: 4px;
  gap: 10px;
}
.status-name {
  font-size: 13px;
  font-weight: 600;
}
.column-header span {
  font-size: 12px;
  background: var(--bg-primary);
  border-radius: 4px;
  color: var(--main-text-color);
  width: 22px;
  height: 22px;
  text-align: center;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column-header h2 {
  margin: 0;
  color: #333;
  font-size: 14px; /* Set font size for all column headers */
}

.brd-task-count {
  background-color: #ddd;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 0.9em;
}

.column-content {
  overflow-y: auto;
  padding: 0 10px;
  flex-grow: 1;
  min-height: 100vh;
  background-color: var(--bg-light-gray);
  border: 0.5px solid var(--border-color);
  border-radius: 16px;
}

.board-view-card {
  cursor: pointer;
  background-color: var(--bg-primary);
  border-radius: 10px;
  margin: 10px 0;
  border: 0.5px solid var(--border-color);
  padding: 10px;
  font-family: 'Plus Jakarta Sans';
}

.br-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 5px 0px;
}
.br-card-date {
  font-size: 12px;
  color: #333;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.br-card-date svg {
  width: 13px;
  height: 13px;
  margin-right: 5px;
}
/* .task-tags {
  line-height: normal;
  background: #e6e6e6;
  padding: 4px 9px 4px 9px;
  color: #1b0404;
  border-radius: 61px;
  font-size: 11px;
  font-weight: 500;
} */

.task-menu {
  color: #333;
  font-size: 16px;
  cursor: pointer;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 0 !important;
}

.bd-task-title {
  color: var(--main-text-color, #2a2e34);
  font-size: 13.5px;
  font-weight: 600;
  margin: 5px 1px 5px 1px;
}

.task-detailed-description {
  color: var(--second-text-color, #87909e);
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
}

.labels-container-board-view {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  margin-top: 5px;
}

/* .label {
  background-color: #e0e0e0;
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 11px;
  font-weight: 500;
  line-height: normal;
} */

/* .avatars {
  display: flex;
  gap: 5px;
  margin-top: 10px;
} */

/* .avatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.meta {
  display: flex;
  gap: 10px;
  font-size: 0.8em;
  color: #999;
  margin-top: 10px;
} */

/* .priority {
  color: red;
  font-weight: bold;
} */

.two-line-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.5em; /* Adjust based on your font size */
  max-height: 3em; /* 2 lines * line-height */
  white-space: normal;
}

@media (max-width: 768px) {
  .board-container {
    width: 100vw;
    /* padding: 10px; */
    position: absolute;
    top: 175px;
    padding: 0px 16px;
  }

  .board {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .column {
    margin-right: 10px;
  }
}

/* Styles for the dragging card */
.board-view-card.dragging {
  border: 1px dashed #a3a2a2;
  opacity: 1;
  background-color: rgb(255, 255, 255, 0.2);
  box-shadow: 0px 2px 10px 3px rgba(0, 0, 0, 0.1); /* Stronger shadow */
  /* Add any other styles you want */
}

/* Styles for the column when a card is dragged over */
.column-content.drag-over {
  background-color: var(--bg-primary);
  /* Add any other styles you want */
}

.board-parent-task-name {
  padding: 4px 9px;
  border-radius: 4px;
  width: fit-content;
  font-size: 11px;
  padding-top: 0;
  background-color: white;
}
