/* trigger node */
.trigger-modal-container-2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  justify-content: space-between;
}
.trigger-content {
  display: flex;
  flex-grow: 1;
  padding: 5px 10px;
  flex-direction: column;
}
/* trigger node */

.trigger-modal-header {
  background: #f9fafb;
  padding: 8px 16px;
  border-bottom: 1px solid #e5e7eb;
}

.trigger-type-select {
  font-size: 14px;
  margin-bottom: 5px;
}

.done-btn {
  border-radius: 3px;
  cursor: pointer;
  background-color: rgb(52, 84, 209);
  color: white;
  border: none;
  outline: none;

  font-size: 13px;
  height: 30px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}


.btn-container {
  display: flex;
  justify-content: space-between;
  gap: 4px;
  padding: 10px 5px;
}

.condition-field-select {
  font-size: 14px;
  margin-bottom: 5px;
}
